import axios from "axios";
import { GET_DASHBOARD } from "./dashboard.type";
import { apiInstanceFetch } from "../../util/api";

export const getDashboard = (sDate, eDate) => (dispatch) => {
  
  apiInstanceFetch
    .get(`dashboard?startDate=${sDate}&endDate=${eDate}`)
    .then((res) => {
      
      dispatch({ type: GET_DASHBOARD, payload: res.dashboard });
    })
    .catch((error) => {
      console.log(error);
    });
};
