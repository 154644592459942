import axios from "axios";
import { Toast } from "../../util/Toast";

import {
  DELETE_LIVE_STEAMING_VIDEO,
  GET_FAKE_USER_LIST,
  GET_LIVE_STEAMING_VIDEO,
  INSERT_LIVE_STEAMING_VIDEO,
  UPDATE_LIVE_STEAMING_VIDEO,
  HANDLE_LOCK_SWITCH,
  CLOSE_LIVE_STREAMING_DIALOG,
} from "./type";
import { apiInstanceFetch } from "../../util/api";

export const getLiveStreamingVideo = (id) => (dispatch) => {
  apiInstanceFetch
    .get(
      id
        ? `fakeLivestreamingVideo/getAll?userId=${id}`
        : `fakeLivestreamingVideo/getAll`
    )
    .then((res) => {
      if (res.status) {
        ;
        dispatch({ type: GET_LIVE_STEAMING_VIDEO, payload: res.video });
      } else {
        Toast("error", res.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const getFakeUserList = () => (dispatch) => {
  apiInstanceFetch
    .get(`user/fake`)
    .then((res) => {
      if (res.status) {
        dispatch({ type: GET_FAKE_USER_LIST, payload: res.user });
      } else {
        Toast("error", res.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

//create livestream video
export const createLiveStreamingVideo = (formData) => (dispatch) => {
  ;
  axios
    .post(`fakeLivestreamingVideo/createVideo`, formData)
    .then((res) => {
      ;
      if (res.data.status) {
        dispatch({ type: CLOSE_LIVE_STREAMING_DIALOG });
        dispatch({ type: INSERT_LIVE_STEAMING_VIDEO, payload: res.data.video });
        Toast("success", "Video Insert Successful");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

//update livestream video
export const updateLiveStreamingVideo = (id,formData) => (dispatch) => {
  axios
    .patch(`fakeLivestreamingVideo/update?fakeVideoId=${id}`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: UPDATE_LIVE_STEAMING_VIDEO, payload: {data:res.data.video , id:id} });
        Toast("success", "Video Edit Successful");

      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const deleteFakeLiveStreamingVideo = (videoId) => (dispatch) => {
  
  axios
    .delete(`fakeLivestreamingVideo/delete?fakeVideoId=${videoId}`)
    .then((res) => {
      
      if (res.data.status) {
        dispatch({ type: DELETE_LIVE_STEAMING_VIDEO, payload: videoId });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

export const handleLockSwitch = (videoId, data) => (dispatch) => {
  ;
  axios
    .patch(`fakeLivestreamingVideo/isLock?fakeVideoId=${videoId}`)
    .then((res) => {
      ;
      if (res.data.status) {
        dispatch({
          type: HANDLE_LOCK_SWITCH,
          payload: res.data.video,
        });
        Toast(
          "success",
          `${data?.userId?.name} is ${
            data.isLock ? "unlocked" : "Locked"
          } SuccessFully ! `
        );
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
