import React, { useEffect, useState } from "react";

// routing
import { Link, useHistory } from "react-router-dom";

// action
import { createNewSong, editSong } from "../../Store/song/action";

// redux
import { connect, useSelector } from "react-redux";
import { baseURL } from "../../util/config";


const SongDialog = (props) => {
  const history = useHistory();

  const [mongoId, setMongoId] = useState("");
  const [title, setTitle] = useState("");
  const [singer, setSinger] = useState("");
  const [song, setSong] = useState(null);
  const [selectSong, setSelectSong] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [imagePath, setImagePath] = useState(null);

  const [errors, setError] = useState({
    image: "",
    title: "",
    singer: "",
    song: "",
  });

  const dialogData = JSON.parse(localStorage.getItem("SongDetail"));
  

  useEffect(() => {
    if (dialogData) {
      setMongoId(dialogData._id);
      setSinger(dialogData.singer);
      setSong(baseURL + dialogData.song);
      setTitle(dialogData.title);
      setImagePath(baseURL + dialogData.image);
    } 
  }, []);

  useEffect(
    () => () => {
      setError({
        image: "",
        title: "",
        singer: "",
        song: "",
      });
      setMongoId("");
      setTitle("");
      setSinger("");
      setSong(null);
      setImageData(null);
      setImagePath(null);
    },
    []
  );

  const HandleInputImage = (e) => {
    if (e.target.files[0]) {
      setImageData(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagePath(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!singer || !title || !song) {
      const errors = {};
      if (!singer) errors.singer = "Singer Detail is required!";
      if (!title) errors.title = "Song Title is required!";
      if (!song) errors.song = "Song is required!";
      if (!imageData || !imagePath) errors.image = "Please select an Image!";

      return setError({ ...errors });
    }
    if (!mongoId) {
      if (
        !imageData ||
        !imagePath ||
        !song?.type?.includes("audio") ||
        !imageData?.type?.includes("image")
      ) {
        const errors = {};
        if (!imageData?.type?.includes("image"))
          errors.image = "Please select valid image!";
        if (!song?.type?.includes("audio"))
          errors.song = "Please select valid audio!";
        if (!imageData || !imagePath) errors.image = "Please select an Image!";

        return setError({ ...errors });
      }
    } else {
      if (
        (!imageData && !imagePath) ||
        (selectSong && !song?.type?.includes("audio")) ||
        (!imageData?.type?.includes("image") && !imagePath)
      ) {
        const errors = {};
        if (!imageData?.type?.includes("image") && !imagePath)
          errors.image = "Please select valid image!";
        if (selectSong && !song?.type?.includes("audio"))
          errors.song = "Please select valid audio!";
        if (!imageData && !imagePath) errors.image = "Please select an Image!";

        return setError({ ...errors });
      }
    }

    const formData = new FormData();

    formData.append("image", imageData);
    formData.append("song", song);
    formData.append("singer", singer);
    formData.append("title", title);
    
    if (mongoId) {
      props.editSong(formData, mongoId);
    } else {
      props.createNewSong(formData);
    }
    setTimeout(() => {
      history.push("/admin/song");
    }, 2000);
  };

  return (
    <>
      <div id="content" class="main-content">
        <div class="layout-px-spacing my-4">
          <div className="row py-2">
            <div class="col-xl-6 col-md-6 col-sm-12 col-12">
              <h4>Song Dialog</h4>
            </div>
            <div class="col-xl-6 col-md-6 col-sm-12 col-12 ">
              <div class="breadcrumb-four float-right">
                <ul class="breadcrumb">
                  <li>
                    <Link to="/admin/dashboard">
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-home"
                      >
                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                        <polyline points="9 22 9 12 15 12 15 22"></polyline>
                      </svg>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/song"> Song</Link>
                  </li>
                  <li class="active">
                    <a href="javscript:void(0);"> Song Dialog</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row layout-top-spacing">
            <div class="col">
              <div class="card">
                <div class="card-body card-overflow">
                  <div class="d-sm-flex align-items-center justify-content-between mb-4"></div>

                  <form>
                    <div className="form-group mt-4">
                      <label className="mb-2 text-gray">Song Title</label>
                      <input
                        type="text"
                        className="form-control"
                        required=""
                        placeholder="Tum se hai - Sadak"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              title: "Song Title is Required!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              title: "",
                            });
                          }
                        }}
                      />
                      {errors.title && (
                        <div className="ml-2 mt-1">
                          {errors.title && (
                            <div className="pl-1 text__left">
                              <span style={{ color: "red" }}>
                                {errors.title}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="form-group mt-4">
                      <label className="text-gray mb-2">Singer Detail</label>
                      <input
                        type="text"
                        className="form-control"
                        required=""
                        placeholder="Ankit Tiwari"
                        value={singer}
                        onChange={(e) => {
                          setSinger(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              singer: "Singer Detail is Required!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              singer: "",
                            });
                          }
                        }}
                      />
                      {errors.singer && (
                        <div className="ml-2 mt-1">
                          {errors.singer && (
                            <div className="pl-1 text__left">
                              <span style={{ color: "red" }}>
                                {errors.singer}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div
                        className={`${
                          mongoId && !selectSong ? "col-md-6" : "col-md-12"
                        }`}
                      >
                        <div className="form-group mt-4">
                          <label className="text-gray mb-2">Song</label>
                          <input
                            type="file"
                            className="form-control form-control-sm"
                            required=""
                            placeholder="Ankit Tiwari"
                            accept="audio/mp3,audio/*"
                            onChange={(e) => {
                              setSong(e.target.files[0]);
                              mongoId && setSelectSong(true);
                            }}
                          />

                          {errors.song && (
                            <div className="ml-2 mt-1">
                              {errors.song && (
                                <div className="pl-1 text__left">
                                  <span style={{ color: "red" }}>
                                    {errors.song}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className={`${
                          mongoId && !selectSong
                            ? "col-md-6 mt-4 pt-3"
                            : "col-md-12"
                        }`}
                      >
                        {mongoId && !selectSong && (
                          <audio
                            controls
                            className="mt-3"
                            style={{ height: 42 }}
                          >
                            <source src={song} type="audio/ogg" />
                          </audio>
                        )}
                      </div>
                    </div>

                    <div className="form-group mt-4">
                      <label className="mb-2 text-gray">Song Image</label>
                      <input
                        type="file"
                        id="image"
                        className="form-control"
                        accept="image/*"
                        required=""
                        hidden
                        onChange={HandleInputImage}
                      />
                      <div className="row">
                        <div
                          className="col-md-1 pointer-cursor ml-2" 
                          style={{
                            height: 130,
                            width: 130,
                            border: "2px dashed gray",
                            textAlign: "center",
                            marginTop: "10px",
                          }}
                          onClick={() =>
                            document.getElementById("image").click()
                          }
                        >
                          <i
                                      className="fas fa-plus "
                                      style={{ paddingTop: 30, fontSize: 70 ,color:"#000" }}
                                    ></i>
                        </div>
                        <div className="col-md-9">
                          {imagePath && (
                            <>
                              <img
                                height="80px"
                                width="80px"
                                alt="app"
                                src={imagePath}
                                // style={{
                                //   boxShadow:
                                //     "0 5px 15px 0 rgb(105 103 103 / 30%)",
                                //   border: "2px solid #fff",
                                //   borderRadius: 10,
                                //   marginTop: 10,
                                //   float: "left",
                                //   objectFit: "cover",
                                // }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                      {errors.image && (
                        <div className="ml-2 mt-1">
                          {errors.image && (
                            <div className="pl-1 text__left">
                              <span style={{ color: "red" }}>
                                {errors.image}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="mt-5">
                      <button
                        type="button"
                        className="btn bg-dark-gradient ml-2 btn-round float-right   icon_margin"
                        onClick={() => {
                          localStorage.removeItem("SongDetail");
                          history.push("/admin/song");
                        }}
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-round ml-1 float-right  banner-button"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { createNewSong, editSong })(SongDialog);
