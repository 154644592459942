//axios
import axios from "axios";
import { Toast } from "../../util/Toast";

//types
import {
  SET_ADMIN,
  UPDATE_PROFILE,
  UPDATE_PROFILE_NAME,
  SIGNUP_ADMIN,
} from "./admin.type";
import { apiInstanceFetch } from "../../util/api";

export const signupAdmin = (signup) => (dispatch) => {
  console.log("createAdmin", signup);
  axios
    .post("admin/signup", signup)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: SIGNUP_ADMIN });
        Toast("success", "Signup Successfully!");
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      Toast("error", error);
      console.log(error);
    });
};

//Login action
export const login = (details) => (dispatch) => {
  axios
    .post("/admin/login", details)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: SET_ADMIN, payload: res.data.token });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch(({ response }) => {
      if (response?.data.message) {
        Toast("error", response.data.message);
      }
    });
};

//Get admin Profile action
export const getProfile = () => (dispatch) => {
  apiInstanceFetch
    .get(`admin`)
    .then((res) => {
      dispatch({ type: UPDATE_PROFILE, payload: res.admin });
    })
    .then((error) => {
      console.log(error);
    });
};

//Update Image
export const updateImage = (formData) => (dispatch) => {
  axios
    .patch(`/admin/updateImage`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: UPDATE_PROFILE, payload: res.data.admin });
        Toast("success", "Image Update Successful ✔");
      } else {
        Toast("error", res.data.message);
      }
    })
    .then((error) => {
      console.log(error);
    });
};

//Update Email and Name
export const updateProfile = (content) => (dispatch) => {
  axios
    .patch(`/admin`, content)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: UPDATE_PROFILE_NAME, payload: res.data.admin });
        Toast("success", " Update Successful ✔");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateCode = (signup) => (dispatch) => {
  console.log("updateCode", signup);
  axios
    .patch("admin/updateCode", signup)
    .then((res) => {
      console.log(res);
      if (res.data.status) {
        console.log("res.data", res.data);
        Toast("success", "Purchase Code Update Successfully !");
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      Toast("error", error);
      console.log(error);
    });
};
