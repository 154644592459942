import axios from "axios";
import { Toast } from "../../util/Toast";
import { GET_FOLLOWER_FOLLOWING_LIST } from "./follower.type";
import { apiInstanceFetch } from "../../util/api";

export const getFollowerList = (type, userId) => (dispatch) => {
  apiInstanceFetch
    .get(`follower?type=${type}&userId=${userId}`)
    .then((result) => {
      if (result.status) {
        
        dispatch({
          type: GET_FOLLOWER_FOLLOWING_LIST,
          payload: result.user,
        });
      } else {
        Toast("error", result.message);
      }
    })
    .catch((error) => {
      Toast("error", error.message);
    });
};
