import { GET_COIN_PLAN_HISTORY, GET_HISTORY } from "./history.type";

const initialState = {
  history: {},
  coinplanHistoryOfUsers: {},
  totalIncome: 0,
  totalOutgoing: 0,
  totalUser: 0,
  totalHistory :0
};

const historyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HISTORY:
      return {
        ...state,
        history: action.payload.history,
        totalIncome: action.payload.income,
        totalOutgoing: action.payload.outgoing,
        totalUser: action.payload.total,
      };
    case GET_COIN_PLAN_HISTORY:
      return {
        ...state,
        coinplanHistoryOfUsers : action.payload.coinplanHistoryOfUsers,
        totalHistory : action.payload.totalHistory
      };
    default:
      return state;
  }
};

export default historyReducer;
