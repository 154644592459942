/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from "react";

// jqury

import $ from "jquery";

// routing
import { Link, useHistory } from "react-router-dom";

// redux
import { connect, useSelector } from "react-redux";

//action
import { getCountry, insertUser, editUser } from "../../Store/FakeUser/Action";


import { baseURL } from "../../util/config";

const FakeUserDialog = (props) => {
  const history = useHistory();
  

  const detail = JSON.parse(localStorage.getItem("fakeUser"));

  const [name, setName] = useState("");
  const [userName, setuserName] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [profileImage, setProfileImage] = useState([]);
  const [coverImage, setCoverImage] = useState([]);
  const [coverImagePath, setCoverImagePath] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [imageType, setImageType] = useState(1);
  const [bio, setBio] = useState("");
  const [mongoId, setMongoId] = useState("");
  const [video, setVideo] = useState([]);
  const [videoType, setVideoType] = useState("1");
  const [videoPath, setVideoPath] = useState("");

  const [errors, setError] = useState({
    name: "",
    userName: "",
    email: "",
    gender: "",
    age: "",
    profileImage: "",
    country: "",
    bio: "",
    coverImage: "",
    video: "",
  });

  useEffect(() => {
    if (detail) {
      setName(detail?.name);
      setuserName(detail?.username);
      setGender(detail.gender);
      setImageType(detail?.imageType);
      setImagePath(detail?.profileImage);
      setCoverImagePath(detail?.coverImage);
      setEmail(detail?.email);
      setBio(detail?.bio);
      setVideoType(detail?.videoType);
      setVideoPath(detail.video);
      setMongoId(detail?._id);
    }
  }, []);

  const HandleInputImage = (e) => {
    if (e.target.files[0] == "") {
      errors.profileImage = "Cover image is require";
    } else {
      errors.profileImage = "";
    }
    setProfileImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };
  const HandleInputCoverImage = (e) => {
    if (e.target.files[0] == "") {
      errors.coverImage = "Cover image is require";
    } else {
      errors.coverImage = "";
    }
    setError({ ...errors });
    if (e.target.files[0]) {
      setCoverImage(e.target.files[0]);
      setCoverImagePath(URL.createObjectURL(e.target.files[0]));
    }
  };

  const HandleInputVideo = (e) => {
    if (e.target.files[0] == "") {
      errors.video = "Video is require";
    } else {
      errors.video = "";
    }

    if (e.target.files[0]) {
      setVideo(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setVideoPath(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", `${baseURL}` + "storage/male.png");
    });
  });
  const isEmail = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(val);
    return validNumber;
  };

  const isLink = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^(ftp|http|https):\/\/[^ "]+$/.test(val);
    return validNumber;
  };

  const handleSubmit = (e) => {
    if (
      !name ||
      !userName ||
      !gender ||
      !email ||
      !bio ||
      !isEmail(email) ||
      (imageType == "0" ? !imagePath || !isLink(imagePath) : !profileImage) ||
      (videoType == "0" ? !videoPath || !isLink(videoPath) : !video)
    ) {
      const errors = {};

      if (!name) errors.name = "Name is Required!";
      if (!userName) errors.userName = "userName is Required!";
      if (!gender) errors.gender = "Gender is Required!";
      if (!email) {
        errors.email = "Email is Required!";
      }
      if (!isEmail(email)) {
        errors.email = "Email Validation Failed!";
      }

      if (!bio) errors.bio = "Bio is Required!";
      if (coverImage?.length === 0 || !coverImage)
        errors.coverImage = "Cover Image is Required!";

      if (imageType == "0" ? !imagePath : profileImage.length === 0)
        errors.profileImage = "User Image is Required !";
      if (imageType == "0" ? !imagePath || !isLink(imagePath) : !profileImage) {
        errors.profileImage = "User Image Link!";
      }
      if (videoType == "0" ? !videoPath : video.length === 0)
        errors.video = "User Video is Required !";
      if (videoType == "0" ? !videoPath || !isLink(videoPath) : !video) {
        errors.video = "User Video Link!";
      }

      return setError({ ...errors });
    } else {
      
      const formData = new FormData();

      formData.append("name", name);
      formData.append("username", userName);
      formData.append("gender", gender);
      formData.append("bio", bio);
      formData.append("email", email);
      formData.append("imageType", imageType);
      formData.append("coverImage", coverImage);
      formData.append("videoType", videoType);
      if (imageType === "0") {
        formData.append("profileImage", imagePath);
      } else {
        formData.append("profileImage", profileImage);
      }

      if (videoType === "0") {
        formData.append("video", videoPath);
      } else {
        formData.append("video", video);
      }

      if (mongoId) {
        props.editUser(mongoId, formData);
      } else {
        props.insertUser(formData);
      }

     
      
    }
  };

  return (
    <>
      <div id="content" className="main-content">
        <div class="layout-px-spacing">
          <div className="row py-2">
            <div class="col-xl-6 col-md-6 col-sm-12 col-12">
              <h4>Fake User Dialog</h4>
            </div>
            <div class="col-xl-6 col-md-6 col-sm-12 col-12 ">
              <div class="breadcrumb-four float-right">
                <ul class="breadcrumb">
                  <li>
                    <Link to="/admin/dashboard">
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-home"
                      >
                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                        <polyline points="9 22 9 12 15 12 15 22"></polyline>
                      </svg>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/fakeUser"> Fake User </Link>
                  </li>
                  <li class="active">
                    <a href={() => false}>Fake</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body card-overflow">
                  <div class="d-sm-flex align-items-center justify-content-between mb-4"></div>

                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="mb-2 text-gray">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            required=""
                            placeholder="Name"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                              if (!e.target.value) {
                                return setError({
                                  ...errors,
                                  name: "name is Required!",
                                });
                              } else {
                                return setError({
                                  ...errors,
                                  name: "",
                                });
                              }
                            }}
                          />
                          {errors.name && (
                            <div className="ml-2 mt-1">
                              {errors.name && (
                                <div className="pl-1 text__left">
                                  <span style={{ color: "red" }}>
                                    {errors.name}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="mb-2 text-gray">Email</label>
                          <input
                            type="email"
                            className="form-control"
                            required=""
                            placeholder="test@gmail.com"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              if (!e.target.value) {
                                return setError({
                                  ...errors,
                                  email: "Email is Required!",
                                });
                              } else {
                                return setError({
                                  ...errors,
                                  email: "",
                                });
                              }
                            }}
                          />
                          {errors.email && (
                            <div className="ml-2 mt-1">
                              {errors.email && (
                                <div className="pl-1 text__left">
                                  <span style={{ color: "red" }}>
                                    {errors.email}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="mb-2 text-gray">UserName</label>
                          <input
                            type="text"
                            className="form-control"
                            required=""
                            placeholder="UserName"
                            value={userName}
                            onChange={(e) => {
                              setuserName(e.target.value);
                              if (!e.target.value) {
                                return setError({
                                  ...errors,
                                  userName: "userName is Required!",
                                });
                              } else {
                                return setError({
                                  ...errors,
                                  userName: "",
                                });
                              }
                            }}
                          />
                          {errors.userName && (
                            <div className="ml-2 mt-1">
                              {errors.userName && (
                                <div className="pl-1 text__left">
                                  <span style={{ color: "red" }}>
                                    {errors.userName}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group ">
                          <label className="mb-2 text-gray">Bio</label>
                          <input
                            type="text"
                            className="form-control"
                            required=""
                            placeholder="Bio"
                            value={bio}
                            onChange={(e) => {
                              setBio(e.target.value);
                              if (!e.target.value) {
                                return setError({
                                  ...errors,
                                  bio: "Bio is Required!",
                                });
                              } else {
                                return setError({
                                  ...errors,
                                  bio: "",
                                });
                              }
                            }}
                          />
                          {errors.bio && (
                            <div className="ml-2 mt-1">
                              {errors.bio && (
                                <div className="pl-1 text__left">
                                  <span style={{ color: "red" }}>
                                    {errors.bio}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-md-6 d-flex justify-content-start">
                        <label className="mb-2 text-gray">Image Type : </label>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="imageType"
                            id="image"
                            value="1"
                            onClick={(e) => {
                              setImageType(e.target.value);
                            }}
                            checked={imageType == "1" ? true : false}
                          />
                          <label class="form-check-label" for="image">
                            Image
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input "
                            type="radio"
                            name="imageType"
                            id="link"
                            value="0"
                            checked={imageType == "0" ? true : false}
                            onClick={(e) => {
                              setImageType(e.target.value);
                            }}
                          />
                          <label class="form-check-label" for="link">
                            Link
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        <div className="d-flex justify-content-start">
                          <label className="mb-2 text-gray">Gender : </label>
                          <div class="form-check">
                            <input
                              class="form-check-input "
                              type="radio"
                              name="gender"
                              id="male"
                              value="male"
                              checked={gender === "male" ? true : false}
                              // onClick={(e) => {
                              //   setGender(e.target.value);
                              // }}
                              onChange={(e) => {
                                setGender(e.target.value);
                                if (!e.target.value) {
                                  return setError({
                                    ...errors,
                                    gender: "Gender is Required!",
                                  });
                                } else {
                                  return setError({
                                    ...errors,
                                    gender: "",
                                  });
                                }
                              }}
                            />
                            <label class="form-check-label" for="male">
                              Male
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="gender"
                              id="female"
                              value="female"
                              checked={gender === "female" ? true : false}
                              onChange={(e) => {
                                setGender(e.target.value);
                                if (!e.target.value) {
                                  return setError({
                                    ...errors,
                                    gender: "Gender is Required!",
                                  });
                                } else {
                                  return setError({
                                    ...errors,
                                    gender: "",
                                  });
                                }
                              }}
                            />
                            <label class="form-check-label" for="female">
                              Female
                            </label>
                          </div>
                        </div>
                        {errors.gender && (
                          <div className="ml-2 mt-1">
                            {errors.gender && (
                              <div className="pl-1 text__left">
                                <span style={{ color: "red" }}>
                                  {errors.gender}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className={imageType == "1" ? "col-md-6" : "d-none"}>
                        <div className="form-group ">
                          <label className="mb-2 text-gray">Image</label>
                          <input
                            type="file"
                            className="form-control form-control-sm"
                            accept="image/*"
                            required=""
                            onChange={HandleInputImage}
                          />
                          {errors.profileImage && (
                            <div className="ml-2 mt-1">
                              {errors.profileImage && (
                                <div className="pl-1 text__left">
                                  <span style={{ color: "red" }}>
                                    {errors.profileImage}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        {imagePath && (
                          <>
                            <img
                              height="60px"
                              width="60px"
                              alt="app"
                              src={imagePath}
                              style={{
                                boxShadow:
                                  "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                marginTop: 10,
                                float: "left",
                                objectFit: "contain",
                                marginRight: 15,
                              }}
                            />
                          </>
                        )}
                      </div>
                      <div className={imageType == "0" ? "col-md-6" : "d-none"}>
                        <div className="form-group">
                          <label className="mb-2 text-gray">Image Link</label>
                          <input
                            type="text"
                            className="form-control"
                            required=""
                            placeholder="Image link"
                            value={imagePath}
                            onChange={(e) => {
                              setImagePath(e.target.value);
                              if (!e.target.value) {
                                return setError({
                                  ...errors,
                                  profileImage: "Image is Required!",
                                });
                              } else {
                                return setError({
                                  ...errors,
                                  profileImage: "",
                                });
                              }
                            }}
                          />
                          {errors.profileImage && (
                            <div className="ml-2 mt-1">
                              {errors.profileImage && (
                                <div className="pl-1 text__left">
                                  <span style={{ color: "red" }}>
                                    {errors.profileImage}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        {imagePath && (
                          <>
                            <img
                              height="60px"
                              width="60px"
                              alt="app"
                              src={imagePath}
                              style={{
                                boxShadow:
                                  "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                marginTop: 10,
                                float: "left",
                                objectFit: "contain",
                                marginRight: 15,
                              }}
                            />
                          </>
                        )}
                      </div>

                      <div className="col-md-6">
                        <div className="form-group ">
                          <label className="mb-2 text-gray">Cover Image</label>
                          <input
                            type="file"
                            className="form-control form-control-sm"
                            accept="image/*"
                            required=""
                            onChange={HandleInputCoverImage}
                          />
                          {errors.coverImage && (
                            <div className="ml-2 mt-1">
                              {errors.coverImage && (
                                <div className="pl-1 text__left">
                                  <span style={{ color: "red" }}>
                                    {errors.coverImage}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                          {coverImagePath && (
                            <>
                              <img
                                height="60px"
                                width="60px"
                                alt="app"
                                src={coverImagePath}
                                style={{
                                  boxShadow:
                                    "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                  border: "2px solid #fff",
                                  borderRadius: 10,
                                  marginTop: 10,
                                  float: "left",
                                  objectFit: "contain",
                                  marginRight: 15,
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div className="row mt-4"></div> */}

                    <div className="row mt-4">
                      <div className="col-md-6 d-flex justify-content-start">
                        <label className="mb-2 text-gray">Video Type : </label>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="videoType"
                            id="video"
                            value="1"
                            onClick={(e) => {
                              setVideoType(e.target.value);
                            }}
                            checked={videoType == "1" ? true : false}
                          />
                          <label class="form-check-label" for="video">
                            Video
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="videoType"
                            id="linkVideo"
                            value="0"
                            checked={videoType == "0" ? true : false}
                            onClick={(e) => {
                              setVideoType(e.target.value);
                            }}
                          />
                          <label class="form-check-label" for="linkVideo">
                            Link
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className={videoType == "1" ? "col-md-6" : "d-none"}>
                        <div className="form-group ">
                          <label className="mb-2 text-gray">video</label>
                          <input
                            type="file"
                            className="form-control form-control-sm"
                            accept="video/*"
                            required=""
                            controls
                            // value={}
                            onChange={HandleInputVideo}
                          />
                          {errors.video && (
                            <div className="ml-2 mt-1">
                              {errors.video && (
                                <div className="pl-1 text__left">
                                  <span style={{ color: "red" }}>
                                    {errors.video}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        {videoPath && (
                          <>
                            <video
                              height="60px"
                              width="60px"
                              alt="app"
                              src={videoPath}
                              style={{
                                boxShadow:
                                  "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                marginTop: 10,
                                float: "left",
                                objectFit: "contain",
                                marginRight: 15,
                              }}
                            />
                          </>
                        )}
                      </div>

                      <div className={videoType == "0" ? "col-md-6" : "d-none"}>
                        <div className="form-group">
                          <label className="mb-2 text-gray">Video Link</label>
                          <input
                            type="text"
                            className="form-control"
                            required=""
                            placeholder="Video linkVideo"
                            value={videoPath}
                            onChange={(e) => {
                              setVideoPath(e.target.value);
                              if (!e.target.value) {
                                return setError({
                                  ...errors,
                                  video: "Video is Required!",
                                });
                              } else {
                                return setError({
                                  ...errors,
                                  video: "",
                                });
                              }
                            }}
                          />
                          {errors.video && (
                            <div className="ml-2 mt-1">
                              {errors.video && (
                                <div className="pl-1 text__left">
                                  <span style={{ color: "red" }}>
                                    {errors.video}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        {videoPath && (
                          <>
                            <video
                              height="60px"
                              width="60px"
                              alt="app"
                              src={videoPath}
                              style={{
                                boxShadow:
                                  "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                marginTop: 10,
                                float: "left",
                                objectFit: "contain",
                                marginRight: 15,
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>

                    <div className="row justify-content-end mr-1">
                      <div className={imagePath ? "mt-5 pt-5" : "mt-5"}>
                        <button
                          type="button"
                          className="btn btn-danger ml-2 btn-round float-right "
                          onClick={() => {
                            history.push("/admin/fakeUser");
                          }}
                        >
                          Close
                        </button>
                        {!mongoId ? (
                          <button
                            type="button"
                            className="btn btn-round float-right btn-info"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-round float-right btn-info"
                            onClick={handleSubmit}
                          >
                            Update
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getCountry, insertUser, editUser })(
  FakeUserDialog
);
