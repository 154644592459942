import React, { Fragment, useEffect, useRef, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//types
import { CLOSE_GIFT_DIALOG } from "../../../Store/Gift/gift.type";

//action
import { editGift } from "../../../Store/Gift/gift.action";

//bashURL
import { baseURL } from "../../../util/config";

//MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

import SVGA from "svgaplayerweb";
import { Cropper } from "react-advanced-cropper";
import html2canvas from "html2canvas";

const Edit = (props) => {
  const dispatch = useDispatch();

  const { dialog: open, dialogData } = useSelector((state) => state.gift);

  const [mongoId, setMongoId] = useState("");
  const [images, setImages] = useState([]);
  const [coin, setCoin] = useState("");
  const [imageData, setImageData] = useState(null);
  const [imagePath, setImagePath] = useState(null);
  const [isSvga, setIsSvga] = useState(true);
  const [image, setImage] = useState("");
  const [isSubmit, setIsSubmit] = useState(true);
  const [cropper, setCropper] = useState(null);

  const imageRef = useRef(null);

  const [errors, setError] = useState({
    image: "",
    coin: "",
  });

  

  useEffect(() => {
    if (dialogData) {
      setMongoId(dialogData._id);
      setCoin(dialogData.coin);
      setImagePath(baseURL + dialogData.image);
      if (dialogData.image.split(".").pop() === "svga") {
        setIsSvga(true);
      } else {
        setIsSvga(false);
      }
    }
    console.log("isSvga", dialogData?.image);
    console.log("isSvga", isSvga);
  }, [dialogData]);

  const onChange = (cropperRef) => {
    setCropper(cropperRef);
  };

  // for .svga file
  // useEffect(() => {
  //   if (isSvga) {
  //     if (!!document.getElementById("svga") && imagePath) {
  //       var player = new SVGA.Player(`div[attr="${mongoId}"]`);
  //       var parser = new SVGA.Parser(`div[attr="${mongoId}"]`);
  //       if (imageData?.preview) {
  //         parser.load(imageData.preview, function (videoItem) {
  //           player.setVideoItem(videoItem);
  //           player.startAnimation();
  //         });
  //       } else {
  //         parser.load(baseURL + dialogData?.image, function (videoItem) {
  //           player.setVideoItem(videoItem);
  //           player.startAnimation();
  //         });
  //       }
  //     }
  //   }
  // }, [imageData, isSvga, imagePath]);

  useEffect(
    () => () => {
      setError({
        image: "",
        coin: "",
      });
      setMongoId("");
      setCoin("");
      setImageData(null);
      setImagePath(null);
    },
    [open]
  );

  const HandleInputImage = (e) => {
    setImage("");
    if (e.target.files[0]) {
      Object.assign(e.target.files[0], {
        preview: URL.createObjectURL(e.target.files[0]),
      });
      setImageData(e.target.files[0]);
      setImages([e.target.files[0]]);
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        setImagePath(reader.result);
        setError({
          image: "",
        });
      });
      reader.readAsDataURL(e.target.files[0]);

      if (e.target.files[0].name.split(".").pop() === "svga") {
        setIsSvga(true);
      } else {
        setIsSvga(false);
      }
    }
  };
  const closePopup = () => {
    dispatch({ type: CLOSE_GIFT_DIALOG });
  };

  const removeImage = () => {
    setImage("");
    setImageData(null);
    setImagePath(null);
    setIsSvga(false);
  };

  useEffect(() => {
    // ;
    // if (imageData || dialogData) {
    if (isSvga) {
      if (!!document.getElementById("svga") && imagePath) {
        var player = new SVGA.Player(`div[attr="${mongoId}"]`);
        var parser = new SVGA.Parser(`div[attr="${mongoId}"]`);
        if (imageData?.preview) {
          parser.load(imageData.preview, function (videoItem) {
            player.setVideoItem(videoItem);
            player.startAnimation();
            setTimeout(() => {
              captureAndSendImage(player, mongoId);
            }, 3000);
          });
        } else {
          parser.load(baseURL + dialogData?.image, function (videoItem) {
            player.setVideoItem(videoItem);
            player.startAnimation();
            setTimeout(() => {
              captureAndSendImage(player, mongoId);
            }, 3000);
          });
        }
      }
    } else {
      setIsSubmit(false);
    }
  }, [imageData, isSvga, imagePath]);

  const captureAndSendImage = (player, index) => {
    return new Promise((resolve) => {
      player.pauseAnimation();

      const container = document.querySelector(`div[attr="${index}"]`);
      const canvas = document.createElement("canvas");

      // Set the desired width and height for the canvas
      const width = container.offsetWidth;
      const height = container.offsetHeight;

      canvas.width = width;
      canvas.height = height;

      html2canvas(container, {
        canvas: canvas,
        width: width,
        height: height,
        scale: 1,
      }).then((canvas) => {
        const data = canvas.toDataURL("image/png");
        resolve(data);
        setImage(data);
        setIsSubmit(false);
      });
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!imagePath || !coin) {
      
      var error = {};
      if (!coin) error.coin = "Coin is Require!";
      if (!imagePath) error.image = "Image is Require!";

      return setError({ ...error });
    } else {
      
      const coinValid = isNumeric(coin);
      if (!coinValid) {
        return setError({ ...errors, coin: "Invalid Coin!!" });
      }

      if (isSvga) {
        const coordinates = cropper?.getCoordinates();
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = coordinates.width;
        canvas.height = coordinates.height;
        context.drawImage(
          imageRef.current,
          coordinates.left,
          coordinates.top,
          coordinates.width,
          coordinates.height,
          0,
          0,
          coordinates.width,
          coordinates.height
        );
        const blob = await new Promise((resolve) => {
          canvas.toBlob((blob) => {
            resolve(blob);
          });
        });
        if (blob) {
          formData.append("svgaImage", blob);
          formData.append("image", imageData);
        }
      }
      const formData = new FormData();

      formData.append("image", imageData);
      formData.append("coin", coin);

      

      props.editGift(mongoId, formData);
    }
  };

  const isNumeric = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^\d+$/.test(val);
    return validNumber;
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="modal-title font-weight-bold h4"> Gift </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="modal-title" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent style={{ overflowY: "hidden" }}>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              {isSvga && (
                <div
                  style={{ position: "absolute", opacity: "0", zIndex: "-111" }}
                >
                  <Cropper
                    defaultCoordinates={{
                      height: 221,
                      left: 77,
                      top: 128,
                      width: 192,
                    }}
                    src={image}
                    onChange={onChange}
                    className={"cropper"}
                  />
                  <img
                    ref={imageRef}
                    src={image}
                    alt="Original"
                    style={{ display: "none" }}
                  />
                </div>
              )}
              <form>
                <div className="form-group">
                  <label className="mb-2 text-gray">coin</label>
                  <input
                    type="number"
                    className="form-control"
                    min={0}
                    required=""
                    placeholder={20}
                    value={coin}
                    onChange={(e) => {
                      setCoin(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...errors,
                          coin: "Coin is Required!",
                        });
                      } else {
                        return setError({
                          ...errors,
                          coin: "",
                        });
                      }
                    }}
                  />
                  {errors.coin && (
                    <span style={{ color: "red" }}>{errors.coin}</span>
                  )}
                </div>
                <div className="form-group mt-4">
                  <label className="mb-2 text-gray">Banner Image</label>
                  <input
                    type="file"
                    className="form-control form-control-sm"
                    accept="image/*,.svga,.gif"
                    required=""
                    onChange={HandleInputImage}
                  />
                  {errors.image && (
                    <span style={{ color: "red" }}>{errors.image}</span>
                  )}
                  {imagePath && (
                    <Fragment>
                      {!isSvga ? (
                        <img
                          src={imagePath}
                          class="mt-3 rounded float-left mb-2"
                          height="100px"
                          width="100px"
                        />
                      ) : (
                        <>
                          <div
                            id="svga"
                            attr={mongoId}
                            style={{
                              boxShadow: "0 5px 15px 0 rgb(105 103 103 / 00%)",
                              float: "left",
                              objectFit: "contain",
                              marginRight: 15,
                              width: "350px",
                              height: "350px",
                            }}
                          ></div>
                        </>
                      )}

                      <div
                        class="img-container"
                        style={{
                          display: "inline",
                          position: "relative",
                          float: "left",
                        }}
                      >
                        <i
                          class="fas fa-times-circle material-icons remove_img text-primary"
                          style={{
                            position: "absolute",
                            right: "-6px",
                            top: "10px",
                            cursor: "pointer",
                          }}
                          onClick={removeImage}
                        ></i>
                      </div>
                    </Fragment>
                  )}
                </div>
                <div className={imagePath ? "mt-5 pt-5" : "mt-5"}>
                  <button
                    type="button"
                    className="btn bg-dark-gradient ml-2 btn-round float-right  icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    // disabled={isSubmit}
                    className=" btn btn-round ml-1 float-right  banner-button"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(null, { editGift })(Edit);
